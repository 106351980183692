import $ from 'jquery';

import Notification from '@js/components/theme/notification';

require('@js/application');

// Flash messages
$('.loca-flash').each((index, element) => {
    const $element = $(element);
    const level = $element.data('level');

    // Let's the Notification component initialize
    setTimeout(function () {
        if (level === 'success') {
            Notification.success($element.html());
        } else if (level === 'error') {
            Notification.error($element.html());
        } else {
            Notification.alert($element.html());
        }
    }, 600);
});
