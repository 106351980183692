import $ from 'jquery';

require('@js/fullPage');
require('@js/modules/validation');

// Track events
$('#user_login')
    .blur(function (event) {
        const value = event.target.value;

        if (value > 0 && window.isMetricsEnabled) {
            window._paq.push(['trackEvent', 'Login form', 'Pseudo']);
        }
    });
$('#user_password')
    .blur(function (event) {
        const value = event.target.value;

        if (value > 0 && window.isMetricsEnabled) {
            window._paq.push(['trackEvent', 'Login form', 'Password']);
        }
    });

$('.connection-google-button')
    .click(function () {
        if (window.isMetricsEnabled) {
            window._paq.push(['trackEvent', 'Login form', 'Google']);
        }
    });
// $('.connection-facebook-button')
//     .click(function () {
//         if (window.isMetricsEnabled) {
//             window._paq.push(['trackEvent', 'Login form', 'Facebook']);
//         }
//     });

$('#login-cancel')
    .click(function () {
        if (window.isMetricsEnabled) {
            window._paq.push(['trackEvent', 'Login form', 'Cancel']);
        }
    });
$('#login_user')
    .submit(function () {
        if (window.isMetricsEnabled) {
            window._paq.push(['trackEvent', 'Login form', 'Submit']);
        }
    });
